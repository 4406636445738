import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/shared/Banner';
import './donors-page.scss';

import { Doughnut } from 'react-chartjs-2';

import { withStyles, lighten } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Spinner from 'react-activity/lib/Spinner';
import 'react-activity/dist/react-activity.css';

import { Collapse } from 'react-collapse';
import { useMediaQuery } from 'react-responsive';

import axios from 'axios';
import { round } from 'lodash';

export const DonorsPageTemplate = ({ banner, entities }) => {
  const [poliBox, setPoliBox] = useState(false);
  const [matchingBox, setMatchingBox] = useState(false);
  const [mbaBox, setMBABox] = useState(false);
  const [countryBox, setCountryBox] = useState(false);

  const togglePoli = () => setPoliBox(!poliBox);
  const toggleMatching = () => setMatchingBox(!matchingBox);
  const toggleMBA = () => setMBABox(!mbaBox);
  const toggleCountry = () => setCountryBox(!countryBox);

  const isMobile = useMediaQuery({ query: '(max-width: 850px)' });

  const [APIData, setAPIData] = useState({
    data: null,
    loaded: false,
  });

  var api_url = process.env.DONATION_API_URL;
  if (!api_url) {
    // api_url = `https://doeapi.amigosdapoli.com.br/api/v1`
    api_url = `http://127.0.0.1:8000/api/v1`
  }

  async function getLeaderboard() {
    return await axios.get(`${api_url}/leaderboard`);
  }
  async function loadData() {
    const response = await getLeaderboard();

    setAPIData({
      data: response.data,
      loaded: true,
    });
  }

  const BarraPoli = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten('#E8E8E8', 0.5),
    },
    bar: {
      backgroundColor: '#00AB96',
    },
  })(LinearProgress);

  const BarraMatching = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten('#E8E8E8', 0.5),
    },
    bar: {
      backgroundColor: '#F05642',
    },
  })(LinearProgress);

  const BarraMBA = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten('#E8E8E8', 0.5),
    },
    bar: {
      backgroundColor: '#F6A317',
    },
  })(LinearProgress);

  const BarraCountry = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten('#E8E8E8', 0.5),
    },
    bar: {
      backgroundColor: '#693A8F',
    },
  })(LinearProgress);

  const BarraDefault = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten('#E8E8E8', 0.5),
    },
    bar: {
      backgroundColor: '#30BCDA',
    },
  })(LinearProgress);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#00AB96',
      },
      poli: {
        main: '#00AB96',
      },
      matching: {
        main: '#BAD54A',
      },
      mba: {
        main: '#F6A317',
      },
      country: {
        main: '#693A8F',
      },
    },
  });
  function LinearProgressWithLabel(props) {
    if (props.entidade === 'poli') {
      return ( <Box alignItems="center" paddingBottom="15px">
          <Box display="flex" justifyContent="space-between">
            <h2 className="our-numbers-day_title_entity">
              <a href={entities.find(entity => entity.name == props.titulo)?.url} target="_blank" onClick={() => props.parent ? togglePoli() : null}>{props.titulo}</a>
            </h2>
            <h2 className="our-numbers-day_title_stats_poli">{`${Math.round(props.value)}% da meta atingida`}</h2>
          </Box>
          <Box width="100%" mr={2} paddingBottom="10px">
            <BarraPoli root={{ height: 100 }} variant="determinate" {...props} value={props.value > 100 ? 100 : props.value} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <h2 className="our-numbers-day_title_number">{props.doadores}</h2>
              &nbsp; <h2 className="our-numbers-day_title_donors">de</h2>
              &nbsp;<h2 className="our-numbers-day_title_number">{props.goal}</h2>
              &nbsp; <h2 className="our-numbers-day_title_donors">doadores</h2>
            </Box>
            {props.parent ? (
              <h2 className="our-numbers-day_title_groups" onClick={togglePoli}>
                ver por grupos doadores
              </h2>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      );
    }
    if (props.entidade === 'matching') {
      return (
        <Box alignItems="center" paddingBottom="15px">
          <Box display="flex" justifyContent="space-between">
            <h2 className="our-numbers-day_title_entity">
              <a href={entities.find(entity => entity.name == props.titulo)?.url} target="_blank" onClick={() => props.parent ? toggleMatching() : null}>{props.titulo}</a>
            </h2>
            <h2 className="our-numbers-day_title_stats_poli">{`${Math.round(props.value)}% da meta atingida`}</h2>
          </Box>
          <Box width="100%" mr={2}>
            <BarraMatching root={{ height: 100 }} variant="determinate" {...props} value={props.value > 100 ? 100 : props.value} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <h2 className="our-numbers-day_title_number">R${props.doadores}</h2>
              &nbsp; <h2 className="our-numbers-day_title_donors">doados</h2>
              &nbsp; <h2 className="our-numbers-day_title_donors">de</h2>
              &nbsp;<h2 className="our-numbers-day_title_number">R${props.goal}</h2>
            </Box>
            {props.parent ? (
              <h2 className="our-numbers-day_title_groups" onClick={toggleMatching}>
                ver por grupos doadores
              </h2>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      );
    }
    if (props.entidade === 'mba') {
      return (
        <Box alignItems="center" paddingBottom="15px">
          <Box display="flex" justifyContent="space-between">
            <h2 className="our-numbers-day_title_entity">
              <a href={entities.find(entity => entity.name == props.titulo)?.url} target="_blank" onClick={() => props.parent ? toggleMBA() : null}>{props.titulo}</a>
            </h2>
            <h2 className="our-numbers-day_title_stats_poli">{`${Math.round(props.value)}% da meta atingida`}</h2>
          </Box>
          <Box width="100%" mr={2}>
            <BarraMBA root={{ height: 100 }} variant="determinate" {...props} value={props.value > 100 ? 100 : props.value} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <h2 className="our-numbers-day_title_number">{props.doadores}</h2>
              &nbsp; <h2 className="our-numbers-day_title_donors">de</h2>
              &nbsp;<h2 className="our-numbers-day_title_number">{props.goal}</h2>
              &nbsp; <h2 className="our-numbers-day_title_donors">doadores</h2>
            </Box>
            {props.parent ? (
              <h2 className="our-numbers-day_title_groups" onClick={toggleMBA}>
                ver por grupos doadores
              </h2>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      );
    }
    if (props.entidade === 'country') {
      return (
        <Box alignItems="center" paddingBottom="15px">
          <Box display="flex" justifyContent="space-between">
            <h2 className="our-numbers-day_title_entity">
              <a href={entities.find(entity => entity.name == props.titulo)?.url} target="_blank" onClick={() => props.parent ? toggleCountry() : null}>{props.titulo}</a>
            </h2>
            <h2 className="our-numbers-day_title_stats_poli">{`${Math.round(props.value)}% da meta atingida`}</h2>
          </Box>
          <Box width="100%" mr={2}>
            <BarraCountry root={{ height: 100 }} variant="determinate" {...props} value={props.value > 100 ? 100 : props.value} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <h2 className="our-numbers-day_title_number">{props.doadores}</h2>
              &nbsp; <h2 className="our-numbers-day_title_donors">de</h2>
              &nbsp;<h2 className="our-numbers-day_title_number">{props.goal}</h2>
              &nbsp; <h2 className="our-numbers-day_title_donors">doadores</h2>
            </Box>
            {props.parent ? (
              <h2 className="our-numbers-day_title_groups" onClick={toggleCountry}>
                ver por grupos doadores
              </h2>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      );
    }
    return (
      <div>
        <Box paddingBottom="15px">
          <Box width="100%">
            <Box display="flex" justifyContent="space-between">
              <h2 className="our-numbers-day_title_entity">OUTROS</h2>
              <h2 className="our-numbers-day_title_stats_default">{`${Math.round(props.value)}% da meta atingida`}</h2>
            </Box>
            <BarraDefault variant="determinate" {...props} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <h2 className="our-numbers-day_title_number"> 1321</h2>
              &nbsp; <h2 className="our-numbers-day_title_stats">doadores</h2>
            </Box>

            <h2 className="our-numbers-day_title_stats">ver por grupos doadores</h2>
          </Box>
        </Box>
      </div>
    );
  }

  if (!APIData.loaded) {
    loadData();
    return (
      <div className="donors">
          <div className="banana" sytle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner color="#30BCDA" size={50} speed={1} animating={true} />
          </div>
      </div>
    );
  }

  const numeroDoadores = APIData.data.header.info[0].num1;
  const metaDoadores = APIData.data.header.info[0].num2;
  const poliObject = APIData.data.entidades_poli;
  const matchingObject = APIData.data.matching;
  const mbaObject = APIData.data.mba;
  const countryObject = APIData.data.country;

  const listPoliEntidades = poliObject && poliObject.data.map((grupo) => {
    return <LinearProgressWithLabel
              value={grupo.progress * 100}
              entidade={'poli'}
              titulo={grupo.name}
              doadores={grupo.value}
              goal={grupo.goal} />
  });
  const listMatchingEntidades = matchingObject && matchingObject.data.map((grupo) => {
    return <LinearProgressWithLabel
              value={grupo.progress * 100}
              entidade={'matching'}
              titulo={grupo.name}
              doadores={grupo.value}
              goal={grupo.goal} />
  });
  const listMBAEntidades = mbaObject && mbaObject.data.map((grupo) => {
    return <LinearProgressWithLabel
              value={grupo.progress * 100}
              entidade={'mba'}
              titulo={grupo.name}
              doadores={grupo.value}
              goal={grupo.goal} />
  });
  const listCountryEntidades = countryObject && countryObject.data.map((grupo) => {
    return <LinearProgressWithLabel
              value={grupo.progress * 100}
              entidade={'country'}
              titulo={grupo.name}
              doadores={grupo.value}
              goal={grupo.goal} />
  });

  const dataDonation = {
    labels: ['Doadores', 'Restante da Meta'],
    datasets: [
      {
        data: [numeroDoadores, metaDoadores - numeroDoadores],
        backgroundColor: ['#30BCDA', '#E8E8E8'],
        hoverBackgroundColor: ['#30BCDA', '#E8E8E8'],
        borderWidth: 0.7,
      },
    ],
  };

  return (
    <div className="donors">
        <section className="our-donors" style={{ paddingBottom: 30, paddingTop: 40 }}>
          <div className="container bars_container">
            <ThemeProvider theme={theme}>
              <LinearProgressWithLabel value={poliObject ? (poliObject.meta > poliObject.value ? (poliObject.value / poliObject.meta) * 100 : 100) : 0} entidade={'poli'} parent={poliObject ? true : false} titulo={'COMUNIDADE POLI'} doadores={poliObject ? poliObject.value : 0} goal={poliObject.meta} />
              <Collapse isOpened={poliBox}>
                <div className="groups_container">{listPoliEntidades}</div>
              </Collapse>
              <LinearProgressWithLabel value={matchingObject ? (matchingObject.meta > matchingObject.value ? (matchingObject.value / matchingObject.meta) * 100 : 100) : 0} entidade={'matching'} parent={matchingObject ? true : false} titulo={'MATCHING'} doadores={matchingObject ? matchingObject.value : 0} goal={matchingObject.meta} />
              <Collapse isOpened={matchingBox}>
                <div className="groups_container">{listMatchingEntidades}</div>
              </Collapse>
              <LinearProgressWithLabel value={mbaObject ? (mbaObject.meta > mbaObject.value ? (mbaObject.value / mbaObject.meta) * 100 : 100) : 0} entidade={'mba'} parent={mbaObject ? true : false} titulo={'MBA'} doadores={mbaObject ? mbaObject.value : 0} goal={mbaObject.meta} />
              <Collapse isOpened={mbaBox}>
                <div className="groups_container">{listMBAEntidades}</div>
              </Collapse>
              <LinearProgressWithLabel value={countryObject ? (countryObject.meta > countryObject.value ? (countryObject.value / countryObject.meta) * 100 : 100) : 0} entidade={'country'} parent={countryObject ? true : false} titulo={'INTERNACIONAL'} doadores={countryObject ? countryObject.value : 0} goal={countryObject.meta} />
              <Collapse isOpened={countryBox}>
                <div className="groups_container">{listCountryEntidades}</div>
              </Collapse>
            </ThemeProvider>
          </div>
        </section>
    </div>
  );
};

const DonorsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
      <DonorsPageTemplate banner={frontmatter.banner} entities={frontmatter.entities} />
  );
};

DonorsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default DonorsPage;

export const leaderboardPageQuery = graphql`
  query leaderboardPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        banner {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          subtitle
        }
        entities {
          name
          url
        }
      }
    }
  }
`;
